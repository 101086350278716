import {
  isBoolean,
  isExact,
  isShape,
  isString,
  isUndefined,
  IValidator,
  or,
  refine
} from '@netvision/lib-react-table'
import { IJoinParameter, isJoinParameter } from './ICellCommon'

export type ICell =
  | ITextCell
  | IPasswordCell
  | IIdCell
  | IRefCell
  | IRefArrayCell
  | IIconCell
  | IImageCell
  | IObjectCell
  | INumberCell
  | IBooleanCell
  | IDateTimeCell
  | IUrlCell
  | IArrayCell

export type ITextCell = {
  type: 'text'
}

export const isTextCell: IValidator<ITextCell> = isShape({
  type: isExact('text')
})

export type IPasswordCell = {
  type: 'password'
}

export const isPasswordCell: IValidator<IPasswordCell> = isShape({
  type: isExact('password')
})

export type IIdCell = {
  type: 'id'
}

export const isIdCell: IValidator<IIdCell> = isShape({
  type: isExact('id')
})

export type IRefCell = {
  type: 'ref'
  idField: string
  typeField?: string
  queryField?: string
  labelField?: string
  typeStatic?: string
  join?: IJoinParameter
  picture?: string
}

export const isRefCell: IValidator<IRefCell> = refine<IRefCell>(
  isShape({
    type: isExact('ref'),
    idField: isString,
    typeField: or(isUndefined, isString),
    typeStatic: or(isUndefined, isString),
    join: or(isUndefined, isJoinParameter),
    picture: or(isUndefined, isString)
  }),
  (v) => !isUndefined(v.typeField) || !isUndefined(v.typeStatic)
)

export type IRefArrayCell = {
  type: 'refArray'
  idsField: string
  typeStatic: string
  join?: IJoinParameter
  picture?: string
}

export const isRefArrayCell: IValidator<IRefArrayCell> = isShape({
  type: isExact('refArray'),
  idsField: isString,
  typeStatic: isString,
  join: or(isUndefined, isJoinParameter),
  picture: or(isUndefined, isString)
})

export type IObjectCell = {
  type: 'object'
  asArray?: boolean
  join?: IJoinParameter
  picture?: string
}

export const isObjectCell: IValidator<IObjectCell> = isShape({
  type: isExact('object'),
  asArray: or(isUndefined, isBoolean),
  join: or(isUndefined, isJoinParameter),
  picture: or(isUndefined, isString)
})

export type ICellFormat = {
  key: string
  config?: Record<string, any>
}

export type INumberCell = {
  type: 'number'
  format?: ICellFormat
}

export const isNumberCell: IValidator<INumberCell> = isShape({
  type: isExact('number')
})

export type IBooleanCell = {
  type: 'boolean'
}

export const isBooleanCell: IValidator<IBooleanCell> = isShape({
  type: isExact('boolean')
})

export type IDateTimeCell = {
  type: 'dateTime'
  max?: number | 'Infinity' | 'now'
  warningPeriodInDays?: number
}

export const isDateTimeCell: IValidator<IDateTimeCell> = isShape({
  type: isExact('dateTime')
})

export type IIconCell = {
  type: 'icon'
}

export const isIconCell: IValidator<IIconCell> = isShape({
  type: isExact('icon')
})

export type IImageCell = {
  type: 'image'
}

export const isImageCell: IValidator<IImageCell> = isShape({
  type: isExact('image')
})

export type IUrlCell = {
  type: 'url'
}

export const isUrlCell: IValidator<IUrlCell> = isShape({
  type: isExact('url')
})

export type IArrayCell = {
  type: 'array'
  items: ICell
}
