/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { INumberCell } from '../../models/ICell'
import { ICellProps } from './selectCell'
import { Formatter } from '../../utils/Formatter'
import { get, isNumber } from 'lodash-es'

export function NumberCell<T extends {}, K extends string>(props: ICellProps<T, K, INumberCell>) {
  const { rowData, field, description } = props
  const number = get(rowData, field, undefined)
  return isNumber(number) ? (
    <span css={$css}>
      {description.format ? Formatter.format(number, description.format) : number.toString()}
    </span>
  ) : null
}

const $css = css`
  font-family: monospace, monospace;
`
