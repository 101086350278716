import { ICellFormat } from '../models/ICell'

export class Formatter {
  static readonly seconds = 1
  static readonly minutes = Formatter.seconds * 60
  static readonly hours = Formatter.minutes * 60
  static readonly days = Formatter.hours * 24
  static readonly weeks = Formatter.days * 7
  static readonly months = Formatter.days * 30
  static readonly years = Formatter.days * 365
  static readonly durationsDict = new Map([
    ['y', Formatter.years],
    ['M', Formatter.months],
    ['w', Formatter.weeks],
    ['d', Formatter.days],
    ['h', Formatter.hours],
    ['m', Formatter.minutes],
    ['s', Formatter.seconds]
  ])
  static readonly formatDict = new Map([
    ['duration', Formatter.durationFormatter],
    ['percent', Formatter.percentFormatter],
    ['currency', Formatter.currencyFormatter]
  ])

  static format(value: number, { key, config }: ICellFormat): string | number {
    return Formatter.formatDict.get(key)?.(value, config) || value
  }

  static durationFormatter(value: number) {
    let durationString = ''
    let leftTime = value
    for (const [symbol, seconds] of Formatter.durationsDict) {
      const quotient = leftTime / seconds
      if (quotient >= 1) {
        leftTime = leftTime - Math.floor(quotient) * seconds
        durationString = durationString
          ? `${durationString} ${Math.floor(quotient)}${symbol}`
          : `${Math.floor(quotient)}${symbol}`
      }
    }
    return durationString
  }

  static percentFormatter(value: number) {
    return `${value}%`
  }

  static currencyFormatter(value: number, config?: any) {
    return value.toLocaleString(
      ...(config || ['ru-RU', { style: 'currency', currency: 'RUB', maximumFractionDigits: 0 }])
    )
  }
}
